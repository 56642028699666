import React from 'react';
import './App.css';

function Media({ eng }) {

  return (
    <div className="mainbody">
      <main className="mainvideo">
        <div style={{ width: "100%", height: "0px", position: "relative", paddingBottom: "56.250%" }}><iframe title="movie" src="https://streamable.com/e/891jwi?autoplay=1&loop=0" frameBorder="0" width="100%" height="100%" allowFullScreen style={{ width: "100%", height: "100%", position: "absolute", left: "0px", top: "0px", overflow: "hidden" }} ></iframe></div>
        <br />
        {eng &&
          <div>
            Video by <a href="https://www.instagram.com/knwatanab/" target="_blank" rel="noopener noreferrer">Ken Watanabe</a><br />
          Music by <a href="https://www.instagram.com/sapphireslows/" target="_blank" rel="noopener noreferrer">Sapphire Slows</a>
          </div>}
        {!eng &&
          <div>
            ビデオ：<a href="https://www.instagram.com/knwatanab/" target="_blank" rel="noopener noreferrer">Ken Watanabe</a><br />
          音楽：<a href="https://www.instagram.com/sapphireslows/" target="_blank" rel="noopener noreferrer">Sapphire Slows</a>
          </div>}
      </main>
      <footer className="footer">
        <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>
        {' · '}<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <div className="safari_only" />
        <div className="safari_only" />
        <div className="safari_only" />
      </footer>
    </div>
  );
}

export default Media;
