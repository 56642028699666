import React, { useEffect } from 'react';
import { useShoppingCart } from 'use-shopping-cart';
import piroshiki from './assets/FoodandDrink/piroshiki ojisan.jpeg';
// import curry from './assets/FoodandDrink/curry.jpg';
// import kaomangai from './assets/FoodandDrink/kaomangai.jpg';
import cafe from './assets/FoodandDrink/cafe.jpeg';
import Sannana from './assets/FoodandDrink/Sannana.jpg';
import tamagosando from './assets/FoodandDrink/tamagosando.jpg';
import niginigi from './assets/FoodandDrink/Niginigi.jpg';
import kebab from './assets/FoodandDrink/Kebab.jpg';
import manamana from './assets/FoodandDrink/manamana.jpg';
import tim from './assets/FoodandDrink/Tim.JPG';


import curryjockey from './assets/FoodandDrink/Curry Jockey.png';


// import paradise from './assets/FoodandDrink/paradise.jpeg';
// import shinchan from './assets/FoodandDrink/shinchan.jpg';
// import omshoten from './assets/FoodandDrink/omshoten.jpg';
import moss from './assets/FoodandDrink/moss.jpg';
import ruro from './assets/FoodandDrink/ruro.jpg';
import absinthe from './assets/FoodandDrink/absinthe.jpg';
// import pho from './assets/FoodandDrink/pho.jpg';



const Accomodation = ({ eng }) => {
  const { clearCart } = useShoppingCart();
  useEffect(() => clearCart(), [clearCart]);

  return (
    <div className="mainbody">
      <main>
      <div className="success">
      <h1>
          {eng ? 
          "Food and Drink" :
          "飲食"}
        </h1><br /><br />
        </div>
        <div>
          {eng ?
            <div>
              Food is a large part of life here in Takigahara. Villagers are generously sharing the wealth of their gardens, sansai is growing in the wilderness of the forest and after sweaty days in the fields we gather around the table for communal feasts. To share these flavourful surroundings with you we have invited a lineup of local chefs and farmers who together will compose a delicious landscape. Please dig in and let their dishes season your day!
          </div>
            :
            <div>
              ここ滝ヶ原では、食が生活の大部分を占めています。畑で汗を流した後は、食卓を囲んでみんなでごちそうを食べます。畑で汗を流した後は、みんなで食卓を囲みます。そんな美味しい環境を皆さんと共有するために、地元のシェフや農家の方々をお招きして、美味しい風景を構成していただきました。ぜひ、皆さんの料理を味わってみてください。
          </div>
          }
          <h2>Food</h2>
          <div className="gallery">
            <div>
              <img className="galleryimg" alt="cafe" src={cafe} /><br /><br />
              <h3><a href="https://www.instagram.com/takigaharafarm/" target="_blank" rel="noopener noreferrer">TAKIGAHARA CAFE</a></h3>
              {eng ?
                <div>
                  <i>Omusubi, Galette, Coffee + more</i><br /><br />
                  Takigahara Cafe will invite for a bite in the fresh morning hours and a cup of coffee to tickle tired minds awake. Local and seasonal ingredients are coming together in a Musubi Set or with a buckwheat galette. The open kitchen and shared tables serve as a host for villagers as well as travellers to have a moment of rest and perhaps an unexpected chat. Keep an eye out for seasonal delights hiding around, like the Shiitake mushrooms that are peeking out at the moment.
                  </div>
                :
                <div>
                  <i>おむすび, ガレット, コーヒーなど</i><br /><br />
                  滝ヶ原カフェでは、爽やかな朝の時間帯に、疲れた心を目覚めさせるコーヒーをご用意しています。地元の旬の食材を使った「むすびセット」や「そば粉のガレット」などがあります。オープンキッチンと共有のテーブルは、村人や旅人の憩いの場であり、思いがけないおしゃべりの場でもあります。今はしいたけが顔を出しているなど、季節の味覚が隠れていることも見逃せないでね。
                  </div>
              }
            </div>

            <div>
              <img className="galleryimg" alt="Piroshiki" src={piroshiki} /><br /><br />
              <h3><a href="https://www.instagram.com/piroshiki_ojisan/" target="_blank" rel="noopener noreferrer">ピロシキおじさん</a></h3>
              {eng ?
                <div>
                  <i>Piroski</i><br /><br />
                  After working as a chef at a Russian restaurant in Harajuku and a cutlet sandwich restaurant in Meissen, he returned to his hometown Takigahara. Under the nickname of “Uncle Piroshki” he serves Piroshki at Takigahara Cafe and other events and has won many peoples hearts. Uncle Piroshki, who meets everyone with a smile, is the chairman of the Takigahara town council this year. Let his joy filled piroshki fill your stomach.
                    </div>
                :
                <div>
                  <i>ピロシキ</i><br /><br />
                  カツサンドのマイセンや原宿の某ロシア料理店でシェフを勤めたあと、地元の滝ヶ原町へ里帰り。ピロシキおじさんの愛称のもと、滝ヶ原カフェやイベントでピロシキを振る舞いたくさんの人を虜に。みんな大好きピロシキおじさんは今年町内会長を勤める。
                    </div>
              }
            </div>

            <div>
              <img className="galleryimg" alt="ruro" src={ruro} /><br /><br />
              <h3>一福堂　ごはんや</h3>
              {eng ?
                <div>
                  <i>Rurohan and Okayu</i><br /><br />
                  Mapo Tofu with no additives and no chemical seasoning, with the same source of medicine and food in mind.
It is an original reproduction of the taste of Taiwanese mothers, cooked for eight hours, and is a new kind of Chinese food that will satisfy both body and soul.
                    </div>
                :
                <div>
                  <i>ルーロー飯と朝粥</i><br /><br />
                  無添加で化学調味料を使わない医食同源を意識した麻婆豆腐。
8時間かけて煮込んだ台湾のお母さんの味をオリジナル再現し、心も体も満たされる新感覚の中華料理です。
                    </div>
              }
            </div>

            <div>
              <img className="galleryimg" alt="tim" src={tim} /><br /><br />
              <h3>Takigahara Craft & Stay</h3>
              {eng ?
                <div>
                  <i>Course Dinner/Brunch</i><br /><br />
                  Foraged, found, fermented local food.
Prepared with respect for the local environment and the gentle shifts between seasons by Tim Mawn.

Booking is required 48 hours in advance, by calling 0761 46 5621 or emailing info@craftandstay.com Takigahara Craft and Stay.</div>
                :
                <div>
                  <i>コースディナー・ブランチ</i><br /><br />
                  採取したもの、見つけたもの、発酵させたローカルフード。
ティム・モーンが、地域の環境や季節の移り変わりに敬意を払ってご用意します。

滝ヶ原クラフト＆ステイにてご予約は48時間前までにお願いします。
電話（0761 46 5621）またはメール（info@craftandstay.com Takigahara Craft and Stay）でお問い合わせください。
                    </div>
              }
            </div>

            

            <div>
              <img className="galleryimg" alt="curry" src={curryjockey} /><br /><br />
              <h3>Curry Jockey</h3>
              {eng ?
                <div>
                  <i>South Indian Curry</i><br /><br />
                  Hi, I'm CJ (Curry Jockey).
Like a DJ, I try to create a curry and atmosphere that suits the occasion.
We propose natural and ethical curry with the use of spices that bring out the best of the ingredients!
</div>
                :
                <div>
                  <i>南インド系のカレー</i><br /><br />
                  こんにちは、CJ（カレージョッキー）です。
DJのようにその場に合ったカレーと空気作りを心がけています。
素材の美味しさを生かしたスパイス使いでナチュラルでエシカルなカレーを提案します！
                    </div>
              }
            </div>

            <div>
              <img className="galleryimg" alt="sannana" src={Sannana} /><br /><br />
              <h3>Sannana Miso</h3>
              {eng ?
                <div>
                  <i>Fermented gelato, amazake smoothie, nomnom sweets</i><br /><br />
                  Hello, we are Sannana Miso.
In addition to our regular range of rice koji miso, chickpea miso and peanut miso
In addition to our regular range of rice koji miso, chickpea miso and peanut miso, we'll be selling a selection of some of our tasty trial miso for the first time, exclusively on Ishinoko!
We will be selling a small amount of each type of miso.
We will also have our popular basil shio koji, amazake, shio koji and lemon enzyme syrup made from rice koji.
</div>
                :
                <div>
                  <i>発酵ジェラート・甘酒スムージー・nomnomのお菓子</i><br /><br />
                  こんにちは、三七味噌です。
普段販売している、米麹味噌・ひよこ豆味噌・ピーナッツ味噌に加え、
今まで試作で少し作っていた味噌の中から美味しいものをIshinokoのみで初販売します！
少量ですが、いろんな種類の味噌を味わってみてください。
また、人気のバジル塩麹や甘酒、塩麹、米麹で作ったレモン酵素シロップも持っていきます。
                    </div>
              }
            </div>

            <div>
              <img className="galleryimg" alt="tamago" src={tamagosando} /><br /><br />
              <h3>だしまきさん</h3>
              {eng ?
                <div>
                  <i>Tamagosando</i><br /><br />
                  It's a simple sandwich with love.

Eggs / from Hirayama, Mikuni-cho

DASHI-MAKI-SAN
</div>
                :
                <div>
                  <i>たまごサンド</i><br /><br />
                  「愛ある素朴なだしまきサンドです」

たまご/三国町平山産

だしまきさん
                    </div>
              }
            </div>

            <div>
              <img className="galleryimg" alt="niginigi" src={niginigi} /><br /><br />
              <h3>にぎにぎさん</h3>
              {eng ?
                <div>
                  <i>Tofu Dessert</i><br /><br />
                  Tofu is a classic Taiwanese dessert.
It is a healthy vegan dessert based on soy milk.
</div>
                :
                <div>
                  <i>豆花</i><br /><br />
                  台湾の定番デザート豆花。
豆乳ベースのヘルシーなヴィーガンスィーツです。
                    </div>
              }
            </div>

            <div>
              <img className="galleryimg" alt="kebab" src={kebab} /><br /><br />
              <h3>Kebab</h3>
              {eng ?
                <div>
                  <i>Kebab</i><br /><br />
                  It's a Turkish kebab.
We have beef and chicken!
Pita bread is homemade!
Come and try it!
</div>
                :
                <div>
                  <i>ケバブ</i><br /><br />
                  トルコめしのケバブです。
ビーフとチキンあります！
ピタパンは自家製！
お召し上がりください！
                    </div>
              }
            </div>

            <div>
              <img className="galleryimg" alt="manamana" src={manamana} /><br /><br />
              <h3>manamana</h3>
              {eng ?
                <div>
                  <i>Pork Bindaru and Veggie Curry</i><br /><br />
                  The first mana comes from the bible as the nourishment which fell from the skies. 
The second mana comes from the Buddhist terminology manas vignana. 
The intuitive consciousness is the 7th out of 8 consciousnesses following the 5 senses and mental consciousness. For Ishinoko festival manamana will be serving Vindaloo (meat/vegan) & sweets. (Mostly organic and zero-waste)
</div>
                :
                <div>
                  <i>ポークビンダルとベジタブルカリー</i><br /><br />
                  ひとつめのmanaは旧約聖書に登場する命繋ぎの食。
ふたつめのmanaは仏教用語の末那識。意識の中の欲を生む自我意識の部分をいい、眼、耳、鼻、舌、身、意という六つの識の背後で働く第7の自我意識の事。
今回はmanamanaはオーガニックビンダルカレーとスイーツで出店します。
                    </div>
              }
            </div>

            

            <div>
              {/* <img className="galleryimg" alt="ruro" src={ruro} /><br /><br /> */}
              <h3>Botanically</h3>
              {eng ?
                <div>
                  <i>Herbal Tea</i><br /><br />
                  BOTANICALLY is... @botanically_natural_apothecary 
A friend and I formed the Kampo Brothers, a Kampo unit in Kamakura, after being shocked by the Kampo medicine we encountered during treatment for a car accident. They plan and run a Kampo lifestyle store, Kamakura Haruyado,"" and have a stall at the Zushi Kaigan Film Festival, among other activities to make Kampo more interesting. Currently, she grows medicinal herbs, mainly Holy Basil, native to India, in two fields including Takigahara, Komatsu City, and makes medicinal herb tea born from her daily practice of preparation. We are working to convey the appeal of the proximity of medicinal herbs in our daily lives.
</div>
                :
                <div>
                  <i>お茶</i><br /><br />
                  BOTANICALLY is… @botanically_natural_apothecary 
交通事故治療の際に出会った漢方に衝撃を受け、友人と共にと漢方ユニット「漢方ブラザーズ」を鎌倉にて結成。漢方のライフスタイルショップ「かまくら晴々堂」の企画運営をはじめ、逗子海岸映画祭での出店など、漢方を面白くする活動を行う。現在は、小松市滝ヶ原など二箇所の畑で、インド原産のホーリーバジルを中心に薬草を育て、日々実践する調合のなかから生まれた薬草茶を作っています。日々の生活に薬草が近いことの魅力を伝える活動を行っています。 
当日は、金沢の老舗和菓子屋出身のDJ、PPTVと共に企画する菓子と茶のユニット「そそそぎ」として、今回初お披露目となる、薬草の入った落雁「やくがん」と、それに合わせてブランドを施した薬草茶の販売も行います。 
▱ 菓茶ユニット「そそそぎ」▱
2500年前に生まれた砂糖。
当時も今も、砂糖は薬のひとつ。
良質な甘味は最上の養生。
祖を注ぐように、
その一粒、その一杯を。
                    </div>
              }
            </div>

          </div>

          <br /><br />

          <div className="center"><h1 >+ More TBA</h1></div>

          <h2>Drink</h2>

          <div className="gallery">
            <div>
              <img className="galleryimg" alt="moss" src={moss} />
              <h3>Moss Bar</h3>
              <i>Natural Wine</i>
            </div>
            <div>
              <img className="galleryimg" alt="absinthe" src={absinthe} />
              <h3>Absinthe Social Club × Abu-Chan</h3>
              <i>Absinthe, Mezcal and Original cocktails</i>
            </div>
            <div>
              <h3>Kirinonaka</h3>
              <i>Cocktails and Tacos</i>
            </div>
            <div>
              {/* <img className="galleryimg" alt="cafe" src={cafe} /> */}
              <h3>Softdrink Bar</h3>
              <i>Local juices and soft drinks</i>
            </div>
          </div>
          <br /><br />
          <div className="center"><h1 >+ More TBA</h1></div>

          {/* <h2>Workshops</h2>
          <div className="gallery">
            <div>
              <h3>Yomogi Body Oil</h3>
              <div>Nao-chan</div>
            </div>
            <div>
              <h3>Sansho</h3>
              <div>Nao-chan</div>
            </div>
          </div> */}

        </div>
      </main>
      <footer className="footer">
        <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>
        {' · '}<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <div className="safari_only" />
        <div className="safari_only" />
        <div className="safari_only" />
      </footer>
    </div>
  );
};

export default Accomodation;