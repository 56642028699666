import React, { useEffect } from 'react';

import { useShoppingCart } from 'use-shopping-cart';

// import hostel from './assets/hostel.jpg';



const Accomodation = ({ eng }) => {
  const { clearCart } = useShoppingCart();
  useEffect(() => clearCart(), [clearCart]);
  console.log(eng)
  return (
    <div className="mainbody">
      <main>
        <div className="success">
          <h1>
            {eng ?
              "Accomodation" :
              "宿泊"}
          </h1><br /><br />
        </div>
        {eng &&
          <p>
            <h3>Free Camping</h3>
            A campsite in a spacious field nestled between the mountains will be provided nearby the main stage, behind TAKIGAHARA CAFE. We ask those wishing to camp to bring their own equipment.  <br /><br />

            {/* <h3>Katayama Ryokkaen</h3>
            This ryokan is in Awazu-onsen, and is a 10 min drive away from ishinoko. It is also serviced by our shuttle bus. There is a 24 hr onsen inside the ryokan, which is free to use for staying guests. Rooms are limited - to book, contact us via <a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a> or <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>. Private room (up to six people): ¥5000/night per person.
                        <br /><br /> */}
            <h3>TAKIGAHARA CRAFT & STAY</h3>

            Our on-site hostel, in a 100-year-old Japanese farmhouse at the foot of Mt. Kurakake. More info and reservations via <a href="https://craftandstay.com" target="_blank" rel="noopener noreferrer">https://craftandstay.com</a><br /><br />
            <h3>Other Ryokan</h3>

            There are a variety of onsen ryokan and hotels 10-15min drive from Takigahara, e.g. in Awazu Onsen, Yamashiro Onsen, Yamanaka Onsen, and Katayamazu Onsen. Please research and book yourself. Hotels/Ryokan in Awazu Onsen will be accessible by the shuttle bus but others will require a car to travel from the festival location.<br /><br />

            {/* <img alt="Flyer" src={hostel} /> */}
          </p>}
        {!eng &&
          <p>
            <h3>無料キャンプ</h3>
            メインステージ近くのTAKIGAHARA CAFEの裏手に、山間部に位置する広々としたフィールドにキャンプサイトを用意します。キャンプを希望される方は、各自で装備をご用意ください。      <br /><br />
            {/* <h3>かたやま緑華苑</h3>
ishinokoから車で10分ほどの粟津温泉にある旅館です。
シャトルバスも運行されています。ご宿泊のお客様は無料で24時間いつでも温泉をご利用いただけます。
お部屋の数には限りがありますので、ご希望の方は<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a>または<a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a> までご連絡ください。
個室（6名様まで）一人当たり1泊¥5000。
            <br /><br /> */}
            <h3>TAKIGAHARA CRAFT & STAY</h3>

            鞍掛山の麓にある築100年の日本家屋を利用したホステルです。詳細・予約は <a href="https://craftandstay.com" target="_blank" rel="noopener noreferrer">https://craftandstay.com</a>
            <br /><br />
            <h3>他の旅館</h3>
            滝ヶ原から車で10～15分のところには、粟津温泉、山代温泉、山中温泉、片山津温泉など、さまざまな温泉旅館・ホテルがあります。粟津温泉のホテル・旅館はシャトルバスで移動できますが、その他のホテル・旅館はフェスティバル会場から車で移動する必要があります。
            <br /><br />
            {/* <img alt="Flyer" src={hostel} /> */}
          </p>}
      </main>
      <footer className="footer">
        <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>
        {' · '}<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <div className="safari_only" />
        <div className="safari_only" />
        <div className="safari_only" />
      </footer>
    </div>
  );
};

export default Accomodation;
