import React from 'react'
import styled from "styled-components";
import { useSpring, animated, config } from "react-spring";

import Brand from "./Brand";
import BurgerMenu from "./BurgerMenu";
import CollapseMenu from "./CollapseMenu";
import { Link } from 'react-router-dom';

const Navbar = (props) => {
  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, -10rem, 0)' },
    transform: 'translate3d(0, 0, 0)',
  });

  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 1200,
    config: config.slow,
  });

  return (
    <>
      <NavBar style={barAnimation}>
        <FlexContainer>
          <Brand/>
          <NavLinks style={linkAnimation}>
            <Link href="/">{props.navbarLanguage==='en' ? "Home" : "ホーム"}</Link>
            <a href="/tickets">{props.navbarLanguage==='en' ? "Tickets" : "チケット"}</a>
            <a href="/music">{props.navbarLanguage==='en' ? "Music" : "音楽"}</a>
            <a href="/foodanddrink">{props.navbarLanguage==='en' ? "Food and Drink" : "飲食"}</a>
            <a href="/activities">{props.navbarLanguage==='en' ? "Activites" : "アクティビティー"}</a>
            <a href="/policy">{props.navbarLanguage==='en' ? "Policy" : "ポリシー"}</a>
            <Link to="/accomodation">{props.navbarLanguage==='en' ? "Accomodation" : "宿泊"}</Link>
            <Link to="/gettinghere">{props.navbarLanguage==='en' ? "Getting Here" : "地図"}</Link>
          </NavLinks>
          <BurgerWrapper>
            <BurgerMenu
              navbarState={props.navbarState} 
              handleNavbar={props.handleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu 
        navbarState={props.navbarState} 
        handleNavbar={props.handleNavbar}
      />
   </>
  )
}

export default Navbar

const NavBar = styled(animated.nav)`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  // background: #2d3436;
  // background-blend-mode: color-burn;
  background:
  linear-gradient(purple, transparent),
  linear-gradient(to top left, lime, transparent),
  linear-gradient(to top right, purple, grey);
  box-shadow: 0 0 5px 1px black;
  background-blend-mode: color-burn;
  z-index: 1;
  font-size: 1.2rem;
`;

const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;;
  justify-content: left;
  // background-blend-mode: color-burn;
  height: 5rem;
`;

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;
  
  & a {
    color: #dfe6e9;
    // text-transform: uppercase;
    font-weight: 600;
    font-size; 3rem;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: #fdcb6e;
      border-bottom: 1px solid #fdcb6e;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
`;
 
const BurgerWrapper = styled.div`
  margin: auto 0;

  @media (min-width: 769px) {
    display: none;
  }
`;