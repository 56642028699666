import React, { useEffect, useState } from 'react';

import { useShoppingCart } from 'use-shopping-cart';

import Navbar from "./components/navbar/Navbar";


const Success = () => {
  const { clearCart } = useShoppingCart();
  const [navbarOpen, setNavbarOpen] = useState(false)
  function handleNavbar() {
    setNavbarOpen(!navbarOpen);
  }
  useEffect(() => clearCart(), [clearCart]);

  return (
    <main>
            <>
        <Navbar 
          navbarState={navbarOpen} 
          handleNavbar={() => handleNavbar()}
        />
        {/* <GlobalStyle /> */}
      </>
      <h3 className="success">
lots of fun activities
      </h3>

    </main>
  );
};

export default Success;
