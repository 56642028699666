import React from 'react'
// import styled from "styled-components";
import { Link } from "react-router-dom";

import logo from "../../assets/white logo.png";

const Brand = () => {
  return (
    <Link to="/">
      <img height="100%" width="200rem" src={logo} alt="Company Logo" />
    </Link>
  )
}

export default Brand

// const Link = styled.img`
//   height: 1%;
//   margin: auto 0;
// `;