import React from 'react';

// import flyer from './assets/print_promo_1_lowres.jpg';
// import flyer from './assets/insta_bigsend_main.jpg';
import flyer from './assets/insta_BOGSEND6_webhq.jpg';


// import flyer from './assets/insta_promo_firstframe.jpg';

import { Link } from "react-router-dom";

// import flyervideo from './assets/insta_promo.mp4';


// import buytickets from './assets/buytickets.png';


// import flyer from './flyer.jpg'
import './App.css';

function App({ eng }) {

  return (
    <div className="mainbody">
      <main className="mainvideo">
        <br />
        <br />
        {/* <br />
        <br /> */}
        <div className="safari_only" />

        <img className="flyer" alt="Flyer" src={flyer} />

        {/* <video height="70%" className='videoTag' poster={flyer} autobuffer autoPlay loop muted playsinline>
        <video height="70%" className='videoTag' preload autoplay muted loop playsinline poster="">
            <source src={flyervideo} type='video/mp4' />
        </video> */}
        <br />

        <Link className="Link" to="/Tickets">
          <button className="buttonwrapperbuytickets">
            {eng ? "Buy Tickets" : "チケット購入"} {">"}
          </button>
        </Link>
        <div className="safari_only" />
        <br />
      </main>
      <main className="mainaftertext">
        {eng &&
          <p className="concept">
            Ritual is a deeply important part of our lives. It gives structure and meaning. Helps us connect with one another. Provides safety, security, and constancy through turbulent times. It operates at the smallest, most private individual scale, up to huge synchronicity with millions of people. We need ritual to truly live, not just survive.
            <br /><br />

            When you settle in a place you develop a close connection with the sights, sounds, the people, the animals, rhythms, the patterns around you. <br /><br />

            Walking daily through the locations where ishinoko will spring to life is a ritual in itself, waters washing over, layers of paint being applied, constructing a spectral reality where the mind runs free. Every pass brings new ideas, new emotions, a deeper connection.<br /><br />

            Music, dancing, sharing food, a smile, laughing together – these bring solidarity across generations, colours, creeds and any other boundaries we erect to try and make sense of this complex and ever-confusing world. <br /><br />

            Takigahara is a small community sharing a strong connection with the land and with one another. ishinoko expresses the relationship between those who were born here long ago, and those who have recently made a new home. <br /><br />

            We have an opportunity to revive old traditions which left when the younger generations moved to the city. ‘Matsuri’ is something which brings communities together and creates a pact, a collective promise, which wraps up the past and sets an intention for the future. <br /><br />

            If you come with an open mind and an open heart, anything is possible &emsp; {"<possible>>"}<br />
          </p>}
        {!eng &&
          <p className="concept">
          それは、私たちの人生で大切なこと。
人生を形作り、意味をもたらし、
互いを結びつけ、変動の時代において安心感や安定感を与えてくれる。
それは、私たち一人一人がいとなむ個人的なものであったり、
時には幾千の人々を巻き込むシンクロニシティであったり。
それは、私たちが必要なもの。
ただ毎日を生き延びるためではなく、
本当の意味で生きるため。
<br /><br />

人は、どこかに根を張り生きているうちに、
周りに見えるものや聞こえるもの、
周りの人々や動物たち、
そしてリズムや習慣に、
深い結びつきを覚えずにはいられない。
<br /><br />

ishinokoが生まれるこの場所を、日々歩くことはそれ自体が、まるで儀式のように思える。
水が全てを洗い流し、絵の具が塗り重ねられるように、
心が自由に解き放たれる、幻影のような現実が作り上げられる。
全ての道のりが新たな考えや感情、より深い結びつきをもたらしてくれる。
<br /><br />

音楽にあわせて歌い踊り、食べものを分かち合い、共に笑い合うことで私たちは連帯できる。
この混沌とした世界を少しでも分かろうとするため作り上げられた、
世代や人種、宗教といった壁を乗り越えて。
<br /><br />

滝ヶ原は土地と人々が互いに深く結びつきあった小さな共同体だ。
ishinokoは、この場所で生まれ育った人々と、この場所を新たに家とした人々との結びつきを表している。
<br /><br />

失われてしまった伝統を再びよみがえらせる機会を、私たちはいま手にしている。
祭りは、人々を結びつけ、過去を精算し、未来への志を胸に抱かせる。
<br /><br />

心を開いて、ここに来れば、なんだってできる、全ては可能なのだから。<br />
          </p>}
          <br /><br />
      </main>
      <footer className="footer">
        <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>
        {' · '}<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <div className="safari_only" />
        <div className="safari_only" />
        <div className="safari_only" />
      </footer>
    </div>
  );
}

export default App;
