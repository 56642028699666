import React from 'react';
import ReactDOM from 'react-dom';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './index.css';
// import VideoTeaser from './VideoTeaser';
// import Success from './Success';
import * as serviceWorker from './serviceWorker';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CartProvider } from 'use-shopping-cart';
// import Tickets from './Tickets';
// import Accomodation from './Accomodation';
// import Activities from './Activites'
// import Foodanddrink from './Foodanddrink';
// import Music from './Music';
// import Policy from './Policy';
// import App from './App'
// import Gettinghere from './Gettinghere';
// import Twentytwenty from './Twentytwenty';
// import SideMenu from './SideMenu';
// import { Link } from "react-router-dom";
// import pixellogo from './assets/pixellogo.png';
// import Menu from './Menu';
import Everything from './Everything';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);


ReactDOM.render(

  <Elements stripe={stripePromise}>
    <CartProvider mode="checkout-session" stripe={stripePromise} currency="JPY">
      <Everything />
      {/* <div className="noscroll">
        <Router>
          <div className="sidemenu">
            <Link to="/">
              <img width="15%" alt="logo" src={pixellogo} />
            </Link>
            <br />
            <Route exact path="/">
              {'>'}
            </Route> <Link className="Link" to="/">Home</Link>
            <br />
            <Route exact path="/tickets">
              {'>'}
            </Route> <Link className="Link" to="/tickets">Tickets</Link>
            <br />
            <Route exact path="/accomodation">
              {'>'}
            </Route> <Link className="Link" to="/accomodation">Accomodation</Link>
            <br />
            <Route exact path="/gettinghere">
              {'>'}
            </Route> <Link className="Link" to="/gettinghere">Getting Here</Link>
            <br />
            <Route exact path="/foodanddrink">
              {'>'}
            </Route> <Link className="Link" to="/foodanddrink">Food and Drink</Link>
            <br />
            <Route exact path="/music">
              {'>'}
            </Route> <Link className="Link" to="/music">Music</Link>
            <br />
            <Route exact path="/video">
              {'>'}
            </Route> <Link className="Link" to="/video">Media</Link>
            <br />
            <br />

            <div><u>EN</u> JP</div>
          </div>
          <div className="topbar">
            <Link to="/">
              <img width="50%" alt="logo" src={pixellogo} />
            </Link>
            <Link className="Link" to="/menu">
            <h1 className="topbarright">
              <Switch>
              <Route exact path="/menu">
              {'x'}
            </Route> 
            <Route path="/">
              {'+'}
            </Route> 
            </Switch>
            </h1>
            </Link>
          </div>
          <Switch>
            <Route exact path="/">
              <App />
            </Route>
            <Route exact path="/success">
              <Success />
            </Route>
            <Route exact path="/tickets">
              <Tickets />
            </Route>
            <Route exact path="/success">
              <Success />
            </Route>
            <Route exact path="/accomodation">
              <Accomodation />
            </Route>
            <Route exact path="/activities">
              <Activities />
            </Route>
            <Route exact path="/foodanddrink">
              <Foodanddrink />
            </Route>
            <Route exact path="/gettinghere">
              <Gettinghere />
            </Route>
            <Route exact path="/music">
              <Music />
            </Route>
            <Route exact path="/policy">
              <Policy />
            </Route>
            <Route exact path="/success">
              <Success />
            </Route>
            <Route exact path="/video">
              <VideoTeaser />
            </Route>
            <Route exact path="/twentytwenty">
              <Twentytwenty />
            </Route>
            <Route exact path="/menu">
              <Menu />
            </Route>
          </Switch>
        </Router>
      </div> */}
    </CartProvider>
  </Elements>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
