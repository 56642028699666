import React, { useEffect } from 'react';
import { useShoppingCart } from 'use-shopping-cart';



const Experiences = ({ eng }) => {
  const { clearCart } = useShoppingCart();
  useEffect(() => clearCart(), [clearCart]);

  return (
    <div className="mainbody">
      <main>
        <h2 className="success">
          {eng ?
            "Experiences" :
            "エクスペリアンス"}
        </h2>
        <div>
          There will be many friends joining us setting up stalls and offering other experiences.<br /><br />
          <div className="gallery">
            <div>
              {/* <img alt="cafe" src={cafe} /><br /><br /> */}
              <h3>TAKIGAHARA CAFE</h3><br />
              {eng ?
                <div>
                  Takigahara Cafe will invite for a bite in the fresh morning hours and a cup of coffee to tickle tired minds awake. Local and seasonal ingredients are coming together in a Musubi Set or with a buckwheat galette. The open kitchen and shared tables serve as a host for villagers as well as travellers to have a moment of rest and perhaps an unexpected chat. Keep an eye out for seasonal delights hiding around, like the Shiitake mushrooms that are peeking out at the moment.
                  </div>
                :
                <div>
                  滝ヶ原カフェでは、爽やかな朝の時間帯に、疲れた心を目覚めさせるコーヒーをご用意しています。地元の旬の食材を使った「むすびセット」や「そば粉のガレット」などがあります。オープンキッチンと共有のテーブルは、村人や旅人の憩いの場であり、思いがけないおしゃべりの場でもあります。今はしいたけが顔を出しているなど、季節の味覚が隠れていることも見逃せないでね。
                  </div>
              }
            </div>
            <div>
              {/* <img alt="Piroshiki" src={piroshiki} /><br /><br /> */}
              <h3>Piroshiki Ojisan</h3><br />
              {eng ?
                <div>
                  After working as a chef at a Russian restaurant in Harajuku and a cutlet sandwich restaurant in Meissen, he returned to his hometown Takigahara. Under the nickname of “Uncle Piroshki” he serves Piroshki at Takigahara Cafe and other events and has won many peoples hearts. Uncle Piroshki, who meets everyone with a smile, is the chairman of the Takigahara town council this year. Let his joy filled piroshki fill your stomach.
                    </div>
                :
                <div>
                  カツサンドのマイセンや原宿の某ロシア料理店でシェフを勤めたあと、地元の滝ヶ原町へ里帰り。ピロシキおじさんの愛称のもと、滝ヶ原カフェやイベントでピロシキを振る舞いたくさんの人を虜に。みんな大好きピロシキおじさんは今年町内会長を勤める。
                    </div>
              }
            </div>
          </div>
        </div>
      </main>
      <footer className="footer">
        <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>
        {' · '}<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <div className="safari_only" />
        <div className="safari_only" />
        <div className="safari_only" />
      </footer>
    </div>
  );
};

export default Experiences;
