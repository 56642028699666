import React, { useEffect } from 'react';

import { useShoppingCart } from 'use-shopping-cart';

const Music = ({ eng }) => {
  const { clearCart } = useShoppingCart();
  useEffect(() => clearCart(), [clearCart]);

  return (
    <div className="mainbody">
      <main>
        {eng ?
          <div>
            <div className="center">
              <h1>
                Music
          </h1></div><br />
            <div className="concept">
              Takigahara lives in a beautiful world of sound. Close your eyes and tune in to the frogs, the dragonflies, cicadas, birds, the brooks, the wind caressing the trees. Nature sets the bar high.<br /><br />

              ishinoko will have two stages, paying the closest attention to sound experience. This is a matter for the whole body, not just the ears. Our main stage features a precision-tuned Funktion-One soundsystem run by a crew with decades of experience, while our second stage has a new custom-built system from one of our ishinoko family, inspired by thoughtful experience of the full range of top Japanese festival audio.<br /><br />

              Artists will take you on a sonic adventure through worlds and emotions. Each day will be different, from an opening evening focussed on regional talent, through a Saturday tracking the sun, and a final day taking us deep into fantasy. The entire event traces a trail from the Japanese countryside matsuri of old, interpreted again for a new era.<br /><br />

              Expect balearic seafoam, digital insects, powerful drums, soulful humanoids, irresistible grooves, disorienting soundscapes, psychedelic trickery, sitar brushstrokes, a carnival of aural delights. Some friends return from last year’s ishinoko carrying a sense of what is possible, others join for the first time and breathe in fresh life. <br /><br />

              Just as the river flows clear from the mountain, let the music carry you to the sea.<br /><br />
            </div>
            <br />
            <div className="center">
              <h1>
                Lineup
          </h1>
              <h2>
                {/* <a href="https://www.instagram.com/7e_romanescos/" target="_blank" rel="noopener noreferrer">7e</a><br /> */}
                <a href="https://www.instagram.com/ryo_calpiss_ymzk/" target="_blank" rel="noopener noreferrer">Calpiss</a><br />
                {/* <a href="https://www.instagram.com/chalin_shadowboxing/" target="_blank" rel="noopener noreferrer">Chalin Bijou</a> (live)<br /> */}
                {/* <a href="https://www.instagram.com/cheeshimizu/" target="_blank" rel="noopener noreferrer">Chee Shimizu</a><br /> */}
                {/* <a href="https://www.instagram.com/_discopants_/" target="_blank" rel="noopener noreferrer">Discopants</a><br /> */}
                {/* <a href="https://instagram.com/hilife.68?igshid=t3eneo4shur0" target="_blank" rel="noopener noreferrer">Doy</a><br /> */}
                <a href="https://www.instagram.com/mr.wobble/" target="_blank" rel="noopener noreferrer">DJ Lawson</a> & <a href="https://www.instagram.com/grazrack/" target="_blank" rel="noopener noreferrer">生ハム</a><br />
                <a href="https://www.instagram.com/dj_nobu_ft/" target="_blank" rel="noopener noreferrer">DJ Nobu</a><br />
                <a href="https://www.instagram.com/fobbyo/" target="_blank" rel="noopener noreferrer">Forbidden Hawaiian Sound Service</a><br />
                <a href="https://www.instagram.com/haruka_ft/" target="_blank" rel="noopener noreferrer">Haruka</a><br />
                <a href="https://instagram.com/_kasekiya?igshid=c0d72xps6cu1" target="_blank" rel="noopener noreferrer">花石家音響</a><br />
                <a href="https://www.instagram.com/kotsu0830/" target="_blank" rel="noopener noreferrer">Kotsu</a><br />
                <a href="https://www.instagram.com/_nep__/" target="_blank" rel="noopener noreferrer">Nep</a>&<a href="https://www.instagram.com/kiyoshi_minami/" target="_blank" rel="noopener noreferrer">GGK</a><br />
                <a href="https://www.instagram.com/p_p_t_v_/" target="_blank" rel="noopener noreferrer">PPTV</a> & <a href="https://www.instagram.com/yadorisha/" target="_blank" rel="noopener noreferrer">MC Las</a> (live)<br />
                <a href="https://www.instagram.com/qmico928/" target="_blank" rel="noopener noreferrer">Qmico</a><br />
                <a href="https://www.instagram.com/shunhor_/" target="_blank" rel="noopener noreferrer">shunhor</a><br />
                <a href="https://www.instagram.com/jinakira/" target="_blank" rel="noopener noreferrer">Tea Young Day</a><br />
                <a href="https://www.instagram.com/yah_ya_official/" target="_blank" rel="noopener noreferrer">∞yah-ya∞</a> (live) + <a href="https://www.yoshidadaikiti.net/" target="_blank" rel="noopener noreferrer">Daikiti Yoshida</a><br />
                <a href="https://www.instagram.com/yama72yama/" target="_blank" rel="noopener noreferrer">YAMA</a><br />
                <a href="https://www.instagram.com/kesuyotsumakiyu/" target="_blank" rel="noopener noreferrer">¥ØU$UK<div className="euro">€</div> ¥UK1MAT$U</a><br />
                <a href="https://www.instagram.com/po00oq/" target="_blank" rel="noopener noreferrer">YPY</a> (live)<br />
              + more
              </h2>
            </div>
          </div>
          :
          <div>
            <div className="center">
              <h1>
                音楽
          </h1></div><br />
            <div className="concept">
            滝ヶ原は、美しい音色に溢れています。
そっと目を閉じて、耳をすましてみてください。

畦道をゆく蛙の鳴き声や、空を飛び回る蜻蛉の羽音に
呼びかける鳥のさえずりに、ささやく小川のせせらぎに
吹き渡る風が木々を撫でる音と、田畑の稲がさざめく音に。

この世界に自然に勝るものはそうそうないと、気づかせてくれるでしょう。
<br /><br />

ishinokoには、耳だけでなく体の隅々まで音楽を楽しんでもらえるよう作られた2つのステージがあります。

メインステージには、数十年の経験を持つ熟練のクルーによって丹念に調律されたファンクションワンのサウンドシステムを用意しています。
セカンドステージには、数々の日本のフェスティバルの高水準なサウンドにインスパイアされた、ishinokoメンバーの一員によって新たに作り上げられたカスタムビルドのシステムを設置しています。
<br /><br />

アーティストたちは、様々な世界と感情へと旅する音の冒険に連れて行ってくれるでしょう。
それぞれの日が特別な一日となるよう、初日の夜は地元のアーティストたちを中心に。
土曜日は太陽の動きを感じながら、月と星を追いかけて。
最終日は深い幻想の世界へと私たちをいざないます。
かつて日本で行われていた、いにしえの祭の記憶を辿りながら、
新たな時代のための、新たな解釈をふまえて。
<br /><br />

ishinokoであなたが出会うのは、
バレアリックな音の飛沫と、デジタルの昆虫の羽ばたき、
力強く唸るドラムに、人間の心を持ったヒューマノイド。
体を突き抜けるグルーヴや、平衡感覚を失うサウンドスケープ、
サイケデリックな策略に、シタールの筆遣い。
それはまさに聴覚の喜びのための祝祭。
去年ishinokoに集った友は、この場所で何ができるかを知っているはずです。
初めて来る人々は、新たな生命の息吹を感じ取ってください。

<br /><br />

山々を流れる河川が、やがて海へと流れるように
音楽に身を任せ、大海原へと旅に出ましょう。
<br /><br />
            </div>
            <br />
            <div className="center">
              <h1>
                ラインアップ
          </h1>
              <h2>
                {/* <a href="https://www.instagram.com/7e_romanescos/" target="_blank" rel="noopener noreferrer">7e</a><br /> */}
                <a href="https://www.instagram.com/ryo_calpiss_ymzk/" target="_blank" rel="noopener noreferrer">Calpiss</a><br />
                {/* <a href="https://www.instagram.com/chalin_shadowboxing/" target="_blank" rel="noopener noreferrer">Chalin Bijou</a> (live)<br /> */}
                {/* <a href="https://www.instagram.com/cheeshimizu/" target="_blank" rel="noopener noreferrer">Chee Shimizu</a><br /> */}
                {/* <a href="https://www.instagram.com/_discopants_/" target="_blank" rel="noopener noreferrer">Discopants</a><br /> */}
                {/* <a href="https://instagram.com/hilife.68?igshid=t3eneo4shur0" target="_blank" rel="noopener noreferrer">Doy</a><br /> */}
                <a href="https://www.instagram.com/mr.wobble/" target="_blank" rel="noopener noreferrer">DJ Lawson</a> & <a href="https://www.instagram.com/grazrack/" target="_blank" rel="noopener noreferrer">生ハム</a><br />
                <a href="https://www.instagram.com/dj_nobu_ft/" target="_blank" rel="noopener noreferrer">DJ Nobu</a><br />
                <a href="https://www.instagram.com/fobbyo/" target="_blank" rel="noopener noreferrer">Forbidden Hawaiian Sound Service</a><br />
                <a href="https://www.instagram.com/haruka_ft/" target="_blank" rel="noopener noreferrer">Haruka</a><br />
                <a href="https://instagram.com/_kasekiya?igshid=c0d72xps6cu1" target="_blank" rel="noopener noreferrer">花石家音響</a><br />
                <a href="https://www.instagram.com/kotsu0830/" target="_blank" rel="noopener noreferrer">Kotsu</a><br />
                <a href="https://www.instagram.com/_nep__/" target="_blank" rel="noopener noreferrer">Nep</a>&<a href="https://www.instagram.com/kiyoshi_minami/" target="_blank" rel="noopener noreferrer">GGK</a><br />
                <a href="https://www.instagram.com/p_p_t_v_/" target="_blank" rel="noopener noreferrer">PPTV</a> & <a href="https://www.instagram.com/yadorisha/" target="_blank" rel="noopener noreferrer">MC Las</a> (live)<br />
                <a href="https://www.instagram.com/qmico928/" target="_blank" rel="noopener noreferrer">Qmico</a><br />
                <a href="https://www.instagram.com/shunhor_/" target="_blank" rel="noopener noreferrer">shunhor</a><br />
                <a href="https://www.instagram.com/jinakira/" target="_blank" rel="noopener noreferrer">Tea Young Day</a><br />
                <a href="https://www.instagram.com/yah_ya_official/" target="_blank" rel="noopener noreferrer">∞yah-ya∞</a> (live) + <a href="https://www.yoshidadaikiti.net/" target="_blank" rel="noopener noreferrer">Daikiti Yoshida</a><br />
                <a href="https://www.instagram.com/yama72yama/" target="_blank" rel="noopener noreferrer">YAMA</a><br />
                <a href="https://www.instagram.com/kesuyotsumakiyu/" target="_blank" rel="noopener noreferrer">¥ØU$UK<div className="euro">€</div> ¥UK1MAT$U</a><br />
                <a href="https://www.instagram.com/po00oq/" target="_blank" rel="noopener noreferrer">YPY</a> (live)<br />
              + more
              </h2>
            </div>
          </div>}
      </main>
      <footer className="footer">
        <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>
        {' · '}<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <div className="safari_only" />
        <div className="safari_only" />
        <div className="safari_only" />
      </footer>
    </div>
  );
};

export default Music;
