import React, { useEffect } from 'react';

import { useShoppingCart } from 'use-shopping-cart';

import { Link } from "react-router-dom";

// import JungleBath from './JungleBath.png';


const Success = () => {
  const { clearCart } = useShoppingCart();

  useEffect(() => clearCart(), [clearCart]);

  return (
    <div className="mainbody">
      <main className="mainvideo">
        <h3 className="success">
          <br /><br />
        Thank you for your purchase. <br />
        ご注文いただき、ありがとうございます。<br /><br />
          {/* {' '}
        <span role="img" aria-label="heart emoji">
          ❤️
        </span> */}
You will receive an email confirming your order soon.<br />
すぐに確定を知らせるメールをお送り致します。<br /><br />
          {/* <img alt={'Jungle Bath'} src={JungleBath} /> */}
          <br /><br />
See you on the dancefloor :)
      </h3>
        <br /><br />
        <Link className="Link" to="/">
          <button className="buttonwrapperclose">
            {"<"} Top
          </button>
        </Link>
        {/* <a href="http://www.ishinoko.jp">
          <button className="buttonwrapperclose">{"< "}Top</button>
        </a> */}
      </main>
      <footer className="footer">
        <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>
        {' · '}<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <div className="safari_only" />
        <div className="safari_only" />
        <div className="safari_only" />
      </footer>
    </div>
  );
};

export default Success;
