import React, { useEffect } from 'react';
import { useShoppingCart } from 'use-shopping-cart';
import { Link } from "react-router-dom";

// import coronaguidelines from './assets/considerations_for_events.pdf';


const Policy = ({ eng }) => {
  const { clearCart } = useShoppingCart();
  useEffect(() => clearCart(), [clearCart]);
  console.log(eng)
  return (
    <div className="mainbody">
      <main>
      <div className="success">
      <h1>
          {eng ? 
          "Policy" :
          "ポリシー"}
        </h1><br /><br />
        </div>
        {eng &&
          <h2>Safer Space</h2>}
        {!eng &&
          <h2>私たちが培いたい空間について</h2>}
        {eng &&
          <p>Here at ishinoko, we are seeking to create spaces where anyone and everyone with an open mind is able to participate in and experience all of the special moments this festival has to offer. In order to ensure this, we have a zero tolerance policy towards any form of harassment. If you notice any behaviour that is making yourself or another person feel uncomfortable, please notify a member of staff, and we will work to resolve the issue immediately. We reserve the right to kick out anyone who is causing distress to other people.<br /><br />
          
          We stand against misogyny, transphobia, racism, ableism, ageism and homophobia, and will always work towards removing behaviour that reinforces these attitudes, both at ishinoko and within our larger communities.<br /><br />
          
          As a festival that is just at the very beginning of its journey, we feel that it is our responsibility to foster and encourage the growth of spaces and connections we share together, so that anyone regardless of their gender, sexuality, ethnicity, abledness and age can feel welcomed and respected. This is something that we will never make any compromises on.<br /><br />
          
          We are also happy to collaborate with and listen to anyone who shares these principles. This goes beyond ishinoko. This is about challenging the systems that we live in, and we firmly believe that festivals are an invaluable resource to aid in this challenge. We all have a shared responsibility to make sure that we give birth to the world we want to live in. Let’s make this an unforgettable experience for everyone. 
      </p>}
        {!eng &&
          <p>私たちishinokoは、このフェスティバルが、オープンマインドな誰もが参加することができ、特別な時間を過ごせる空間となることを目指しています。<br /><br />

          そのためishinokoは、いかなる形のハラスメントも認めません。ご自身や他の方が不快に感じられるような行為に遭われた、もしくは発見された場合はすぐにスタッフにお知らせください。直ちに対応いたします。他の参加者に危害を与えられた方には、退場いただきます。
          私たちは女性蔑視、トランスフォビア、人種差別、身体差別、年齢差別、同性愛嫌悪を認めません。そしてishinokoだけではなく、より大きなコミュニティの中でもこのような差別行為を排除するために常に努力しています。<br /><br />
          
          まだまだ始まったばかりのフェスティバルですが、だからこそジェンダー、セクシュアリティ、人種、身体、年齢に関わらず、誰もが歓迎され、尊重されていると感じられるような空間を作っていく責任があると考えています。この点に関しては、これからも決して妥協しません。<br /><br />
          
          この考え方に共感してくれる人たちとぜひ協力していきたいと考えています。何かご意見があれば気軽にお伝えください。<br /><br />
          
          最後に、これは決してishinokoに限った話ではありません。
          この考え方を、私たちが生きている社会システムや制度に対しても広めていかねばなりません。音楽フェスティバルは、そんな未来を作るための一歩になると考えています。自分が生きたいと思える世界を作るために、私たちはみな努力する責任があります。ishinokoでみんなで忘れられない思い出をたくさんつくりましょう。
          </p>}
      <br />
        {eng &&
          <h2>COVID-19 Policy</h2>}
        {!eng &&
          <h2>COVID-19の対策</h2>}
        {eng &&
          <p>
            We are monitoring the ongoing situation with Covid-19 and will be taking every measure to ensure a safe event for attendees and the surrounding community.<br /><br />

            <h3>Basic rules</h3>
            - Temperatures will be tested regularly<br />
            - Mask-wearing will be compulsory, please bring a non-woven mask. Free masks will be made available at the event.<br />
            - Alcohol spray will be freely available. Please disinfect your hands regularly.<br /><br />

            <h3>Social distance</h3>
            - Maintain 1m distance between yourself and others<br />
            - Staff will be checking to ensure that distance is maintained<br />
            - Staff will be on hand to safely guide you between locations<br />
            - Plastic sheets and partitions will be used where necessary<br /><br />

            <h3>Preventing congregations</h3>
            - Staff will be monitoring areas to prevent congestion<br />
            - Staff will be on hand to guide around potential congestion points<br />
            - Entry and exit to the event will be staggered over time to prevent congestion<br /><br />

            <h3>Food and drink</h3>
            - Specific areas will be designated for eating and drinking. Food and drink will not be permitted outside of these areas.<br />
            - Appropriate measures will be taken during set-up and take-down of the event, and during rest periods such as at night in order to ensure safety and cleanliness of all catering areas<br />
            {/* - Excessive alcohol consumption is not permitted<br /> */}
            - Sharing of food and drinks is strictly forbidden<br /><br />

            <h3>Shouting</h3>
            - Loud voices, shouting, and singing will not be permitted. Staff will be on hand checking people’s compliance.<br /><br />

            <h3>Managing facilities used for the event</h3>
            - Appropriate measures will be used when setting up and taking down all areas of the event, such as mask-wearing, disinfection, and social distancing.<br />
            - Scheduling will be used to stagger the setting up of vendors and stallholders to prevent congestion.<br /><br />

            <h3>Contact tracing</h3>
            - Upon arrival at the event it is compulsory to provide contact details (name, phone number or email, place travelled from). This information will be stored for 2 weeks only and then destroyed or deleted.<br />
            - Please buy tickets in advance via <Link to="/tickets">https://ishinoko.jp/tickets</Link><br />
            - We will have a contact tracing service available to use during the event<br /><br />

            <h3>Additional Conditions</h3>
            - Drinking alcohol is prohibited on all festival grounds.<br />
            - Smoking is prohibited in certain areas.<br />
            - No shouting (applause or gestures).<br />
            - Minimum distance of 1m.<br />
            - PCR test of the performer<br />
            - Customer antigen test (maximum of 150 or 200 customers)<br />
            - Separation of artists and audience.<br /><br />

            <h3>Entry is not allowed if you have below symptoms/cases</h3>
            - Fever, sore-throat, chills, fatigue, cough, diarrhea, taste/smell disorder.<br />
            - If you had a close contact with people who tested positive with Covid-19.<br />
            - Those who suspect that a family member or close acquaintance has been infected.<br />
            - Those who have travelled from a country where the government has imposed entry restriction or required a self-quarantine within the past 14 days, or had a close contact with a resident of these countries.<br /><br />
      </p>}
        {!eng &&
          <p>
            我々は、COVID-19の状況を注視しており、参加者や周辺地域の方々にとって安全なイベントを確保するためにあらゆる手段を講じています。<br /><br />

            <h3>ベーシックなルール</h3>
            - 定期的に参加者の検温行います。<br />
            - マスクの着用が義務付けられていますので、不織布のマスクをご用意ください。会場では無料でマスクを配布します。<br />
            - アルコール消毒をご用意しております。定期的に手を消毒してください。<br /><br />

            <h3>ソーシャルディスタンス</h3>
            - 自分と他の人との間に1m以上の距離を保ってください。<br />
            - スタッフがソーシャルディスタンスが保たれているか確認します。<br />
            - 移動の際、スタッフはお客様を安全にご案内いたします。<br />
            - 必要に応じてビニールシートや仕切りを設置します。<br /><br />

            <h3>混雑の防止</h3>
            - 混雑しないようにスタッフが管理します。<br />
            - 混雑が予想される場所には、混雑を避けるためスタッフがご案内します。<br />
            - 混雑防止のため、時間をずらしての入退場となります。<br /><br />

            <h3>飲食</h3>
            - 指定された場所での飲食をお願いします。それ以外の場所での飲食は禁止です。<br />
            - 会場の設営・撤去時や夜間の終演後には、飲食エリアの安全と清潔を保つために適切な措置を講じます。<br />
            {/* - 過度な飲酒は控えてください。<br /> */}
            - 食べ物や飲み物を他の人シェアすることは厳禁です。<br /><br />

            <h3>大声を出すこと</h3>
            - 大きな声、叫び声、歌うことは禁止です。スタッフが確認を行います。<br /><br />

            <h3>会場の管理</h3>
            - 設営・撤去の際には、マスクの着用、消毒、ソーシャルディスタンスの取り方など、適切な処置を行います。<br />
            - 出店者の設置時間をずらし、混雑を防ぐスケジューリングを行います。<br /><br />

            <h3>接触追跡</h3>
            - ご来場の際には、ご連絡先（お名前、電話番号またはEメール、ご旅行先）の入力が必須となります。これらの情報は2週間のみ保存され、その後破棄または削除されます。<br />
            - <Link to="/tickets">https://ishinoko.jp/tickets</Link> でチケットを事前にご購入ください。<br />
            - イベント期間中は、接触追跡サービスをご利用いただけます。<br /><br />

            <h3>追加開催条件</h3>
            ・飲酒禁止（音楽祭会場では全面禁止）<br />
 ・喫煙禁止（喫煙場所を特定）<br />
 ・大声禁止（拍手やゼスチャー）<br />
 ・お客の場所の特定。最低１ｍの距離（着座又は枠）<br />
 ・主演者　ＰＣＲ検査<br />
 ・お客様　抗原検査（お客様の上限は150人又は200人）<br />
 ・主演者とお客様の分離<br /><br />

            <h3>以下の症状をお持ちの方は、ご入場いただけません</h3>
            - 発熱、喉の痛み、悪寒、倦怠感、咳、下痢、味覚・嗅覚障害。<br />
            - Covid-19の陽性反応が出た人と密接な接触があった方。<br />
            - 家族や親しい知人が感染したと思われる方。<br />
            - 過去14日以内に政府が入国制限を課したり、自己検疫を要求した国から渡航した方、またはこれらの国から来た人と密接に接触した方。<br /><br />
          </p>}
      </main>
      <footer className="footer">
        <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>
        {' · '}<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <div className="safari_only" />
        <div className="safari_only" />
        <div className="safari_only" />
      </footer>
    </div>
  );
};

export default Policy;
