import React, { useEffect, useRef, useState } from 'react';

import { useShoppingCart } from 'use-shopping-cart';

import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = 'pk.eyJ1IjoiZ3JhaGFtZGF2aWVzIiwiYSI6ImNrbmw1Z3h0aDBjdjEyb3F2MDdlb3U1aHkifQ.Dq7j87hm7P6p2XFZLWGEIQ';

const Policy = ({ eng }) => {
  const [loading, setLoading] = useState(false);

  const { clearCart } = useShoppingCart();
  useEffect(() => clearCart(), [clearCart]);

  const mapContainer = useRef();
  const [lng, setLng] = useState(136.424903);
  const [lat, setLat] = useState(36.289858);
  const [zoom, setZoom] = useState(5);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/grahamdavies/cknl6vm942mwr17odyg3tr7gb',
      center: [lng, lat],
      zoom: zoom
    });

    setLoading(true)

    var geojson = {
      'type': 'FeatureCollection',
      'features': [
        {
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': [136.424903, 36.289858]
          },
          'properties': {
            'titlejp': "会場",
            'titleen': "Festival Location",
            'descriptionen': '66 Takigaharamachi, Komatsu, Ishikawa 923-0335, Japan',
            'descriptionjp': '石川県小松市滝ケ原町ヲ-66'
          }
        },
        {
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': [-122.414, 37.776]
          },
          'properties': {
            'titleen': 'Mapbox',
            'descriptionen': 'San Francisco, California'
          }
        }
      ]
    };

    // add markers to map
    geojson.features.forEach(function (marker) {
      // create a HTML element for each feature
      var el = document.createElement('div');
      el.className = 'marker';
      // make a marker for each feature and add it to the map
      console.log(eng)
      eng ?
        new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                '<h3>' +
                marker.properties.titleen +
                '</h3><p>' +
                marker.properties.descriptionen +
                '</p>'
              )
          )
          .addTo(map)
        :
        new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // add popups
              .setHTML(
                '<h3>' +
                marker.properties.titlejp +
                '</h3><p>' +
                marker.properties.descriptionjp +
                '</p>'
              )
          )
          .addTo(map);
    });

    !loading && map.flyTo({ center: [lng, lat], zoom: 10, duration: 30000 });
    map.addControl(new mapboxgl.ScaleControl({ position: 'top-right' }));

    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });
    return () => map.remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eng]);

  return (
    <div className="mainbody">
      <main>
        <div className="success">
        <h1>
          {eng ?
            "Access" :
            "アクセス"}
        </h1>
        <br /><br />
        </div>
        <div>
          <div className="map-container" ref={mapContainer} />
        </div>
        <br /><br />
        {eng ? 
        <div>
          ishinoko is held in Takigahara, a secluded and rural village in Ishikawa.<br /><br />
          Festival Location:<br />
        66 Takigaharamachi, Komatsu, Ishikawa 923-0335, Japan<br />
                
        <h2>Festival Shuttle Bus</h2>
            We will run a shuttle bus service that runs as a loop between Awazu Station -{'>'} Awazu Onsen -{'>'} Festival Location -{'>'} Awazu Station.<br /><br />
            Awazu station (Train access to airport and cities)<br />
            Awazu onsen (A small onsen town with a variety of ryokan, onsen and restaurants)<br />
            Festival Location (ishinoko)<br />
          </div>
        : 
        <div>
          ishinokoは、石川県の秘境ともいえる滝ヶ原というところで開催されます。<br /><br />
          会場の住所:<br />
          石川県小松市滝ケ原町ヲ-66<br />

          <h2>フェスのシャトルバス</h2>
            粟津駅-{'>'} 粟津温泉 -{'>'} 会場 -{'>'} 粟津駅との間を循環するシャトルバスを運行します。 <br /><br />
            粟津駅 (空港や都市への鉄道アクセス)<br />
            粟津温泉 (旅館、温泉、レストランが揃う小さな温泉街)<br />
            会場 (ishinoko)<br />
          </div>}
        <br />
        {eng &&
          <div>
            <h2>To Takigahara</h2>
            <h3>By Car</h3>
        Takigahara is quickly accessible from nearby interchanges from Kanazawa and Fukui directions. If you come by car please be aware that you will need a parking ticket to park at the festival.
        <br />
            <br />
            <h3>By Plane</h3>
      1hr from Tokyo Haneda, ~¥25,000 round trip<br />
            <blockquote>
              Fly to Komatsu airport (1hr from Tokyo Haneda), then either:<br />
            - Bus to Komatsu Station (10mins) -{">"} train to Awazu station (5mins) -{">"} shuttle bus to Takigahara (15mins)<br />
            - Rent car from airport e.g. Orix, drive to Takigahara (30mins). You will need a parking ticket to park at the festival.
      </blockquote>
            <h3>By Train</h3>
            <h4>From Tokyo station:</h4>
      3hrs, ¥30,000 round trip or up to 30% cheaper via <a href="https://www.eki-net.com" target="_blank" rel="noopener noreferrer">eki-net</a><br />
            <blockquote>
              Shinkansen to Kanazawa (3hrs), then either:<br />
              - Train to Awazu station (40mins) + shuttle bus to Takigahara (15mins) <br />
              - Rent car from Kanazawa / Komatsu / Kagaonsen station, drive to Takigahara (1hr / 30mins / 15mins). You will need a parking ticket to park at the festival.
      </blockquote>
            <h4>From Osaka station:</h4>
      2hrs, ¥14,000 round trip<br />
            <blockquote>
              JR Thunderbird to Awazu/Kagaonsen station (2hrs), then either:<br />
            - Shuttle bus to Takigahara (15mins)<br />
            - Rent car, drive to Takigahara (15mins). You will need a parking ticket to park at the festival.
      </blockquote>
            <h3>By Highway Bus</h3>
      ~ 8hrs from Tokyo, ~¥8,000 round trip<br />
            <blockquote>
              Bus to Kagaonsen / Komatsu / Kanazawa (~8hrs from Tokyo, e.g. via <a href="https://www.bushikaku.net" target="_blank" rel="noopener noreferrer">bushikaku.net</a>)<br />
            – Train to Awazu station -{">"} shuttle bus to Takigahara (15mins) <br />
            - Rent car from Kanazawa / Komatsu / Kagaonsen, drive to Takigahara (1hr / 30mins / 15mins). You will need a parking ticket to park at the festival.
      </blockquote>
            <h3>Taxis</h3>
      From Komatsu airport / Komatsu station (30mins) ~¥8,000<br />
      From Awazu／Kagaonsen station (15mins) ~¥4,000
      <br /><br />
          </div>}
        {!eng &&
          <div>
            <h2>滝ヶ原へ</h2>
            <h3>車で</h3>
            滝ヶ原は、金沢や福井方面からのインターチェンジからのアクセスも良好です。お車でお越しの方は、駐車場のチケットが必要となりますので、ご注意ください。
           　<br /><br />
            <h3>飛行機で</h3>
      東京羽田から1時間, ~25,000円 往復
      <blockquote>
              小松空港に着きましたら、下記のルートをご利用ください。<br />
        - 小松駅行きのシャトルバス (10分) →粟津駅行きの電車 (5分) →滝ケ原行きのシャトルバス (15分)
        </blockquote>
  ＊　小松空港にORIXなどのレンタカーサービスもあります。会場まで30分かかります。会場に駐車する場合は駐車券が必要です。<br /><br />
      <h3>電車で</h3>
            <h4>東京駅から</h4>
      3時間, 〜30000円　往復、 30%キャンペーンで　〜21000円　往復（<a href="https://www.eki-net.com" target="_blank" rel="noopener noreferrer">eki-net</a>で検索）
      <blockquote>- 金沢駅行きの新幹線 (3時間) →粟津駅行きの電車 (40分) →滝ケ原行きのシャトルバス (15分)</blockquote>
            <h4>大阪駅から</h4>
      2時間, 〜14000円 往復
      <blockquote>- 粟津駅・加賀温泉行きのJ Rサンダーバード (2時間) →滝ケ原行きのシャトルバス (15分) </blockquote>
            <p>
      ＊　金沢・小松・加賀温泉では、レンタカー もあります。会場までにそれぞれ1時間・30分・15分かかります。会場に駐車する場合は駐車券が必要です。
      </p>
            <h3>高速バスで</h3>
      東京から〜8時間, 〜8000円 往復　（<a href="https://www.bushikaku.net" target="_blank" rel="noopener noreferrer">bushikaku.net</a>で検索）
      <blockquote>小松、または金沢行きのバス(東京から約8時間)→粟津駅行きの電車(45分)→滝ケ原行きのシャトルバス (15分)</blockquote>
      ＊　出発地によって加賀温泉行きのバスも使えます。バス比較でルートを確認ください。<br />
      ＊　金沢・小松・加賀温泉では、レンタカー もあります。会場までにそれぞれ1時間・30分・15分かかります。会場に駐車する場合は駐車券が必要です。<br /><br />
      <h3>タクシーで</h3>
      小松空港、または小松駅→滝ケ原 (30分) 約¥8,000<br />
      粟津駅・加賀温泉駅→滝ケ原 (15分) 約¥4,000
      <br /><br />
          </div>}
        <br />
      </main>
      <footer className="footer">
        <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>
        {' · '}<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <div className="safari_only" />
        <div className="safari_only" />
        <div className="safari_only" />
      </footer>
    </div>
  );
};

export default Policy;
