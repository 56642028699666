import React from 'react';
// import CartSummary from './components/CartSummary';
// import Products from './components/Products';


// import header from './ishinoko_header.jpg';
import './App.css';

// import kurakakeyama from './kurakakeyama.jpeg';

// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop-60)   
console.log('showing app')
function Tickets({eng}) {
  // const myRef = useRef(null)
  // const executeScroll = () => scrollToRef(myRef)

  // console.log(language)
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])
  return (
    <div className="mainbody">
      <main>
        <div className="success">
      <h1>
          {eng ? 
          "Tickets" :
          "チケット"}
        </h1>
        </div>
        {eng ? 
          <div>
            <br /><br />
            Advance ticket sales have now closed. Tickets will still be available on the door throughout the festival. The price on the door is: <br /><br />
            1 day - 5000円<br />
            3 day - 12000円<br /><br />

            See you on the dancefloor :)
            </div>
             :
             <div>
               <br /><br />
               前売券の販売は終了しました。当日券はフェスの受付で終日販売いたします。当日券の価格は、以下となります。 <br /><br />
            １日券 - 5000円<br />
            ３日券 - 12000円<br /><br />

            See you on the dancefloor :)
             </div>}
        {/* <br />
        <br />
        <Products eng={eng} />
        <br />
        <br />
        <CartSummary eng={eng}/>
        {eng ?
          <p>
            Refunds will be accepted until 1 week before the event (10/1), we may consider refunds after that date in extreme circumstances. Email <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a> to request a refund.
        <br /><br />
        Payments are handled through Stripe. Upon clicking purchase you will be directed to a secure Stripe payment page. 
      </p>
        :
          <p>
            返金はイベントの1週間前(10/1)までとさせていただきますが、極端な状況下ではそれ以降の返金も検討させていただきます。「<a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>」までご連絡ください。
  <br /><br />
  お支払いはStripeを介して処理されます。購入をクリックすると、安全なStripeの支払いページに移動します。</p>}
        <br />
        <br /> */}
      </main>
      <footer className="footer">
        <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>
        {' · '}<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <div className="safari_only" />
        <div className="safari_only" />
        <div className="safari_only" />
      </footer>
    </div>
  );
}

export default Tickets;
