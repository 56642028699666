import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './index.css';
import Success from './Success';
import Tickets from './Tickets';
import Accomodation from './Accomodation';
import Activities from './Activites'
import Foodanddrink from './Foodanddrink';
import Music from './Music';
import Policy from './Policy';
import App from './App'
import Gettinghere from './Gettinghere';
import Twentytwenty from './Twentytwenty';
// import SideMenu from './SideMenu';
import { Link } from "react-router-dom";
import pixellogo from './assets/pixellogo.png';
import Media from './Media';
import Experiences from './Experiences';
// import NotFound from './NotFound';

// import Menu from './Menu';




function Everything() {

  const Menu = () => {
    return (
      <div className="mainbody">
        <div className="menutext">
          <main className="mainvideo">
            <Link className="Link" to="/"><h1 onClick={() => setShowMenu(false)}>{eng ? "Home" : "ホーム"}</h1></Link>
            <Link className="Link" to="/tickets"><h1 onClick={() => setShowMenu(false)}>{eng ? "Tickets" : "チケット"}</h1></Link>
            <Link className="Link" to="/music"><h1 onClick={() => setShowMenu(false)}>{eng ? "Music" : "音楽"}</h1></Link>
            <Link className="Link" to="/foodanddrink"><h1 onClick={() => setShowMenu(false)}>{eng ? "Food and Drink" : "飲食"}</h1></Link>
            {/* <Link className="Link" to="/experiences"><h1 onClick={() => setShowMenu(false)}>{eng ? "Experiences" : "エクスペリアンス"}</h1></Link> */}
            <Link className="Link" to="/accomodation"><h1 onClick={() => setShowMenu(false)}>{eng ? "Accomodation" : "宿泊"}</h1></Link>
            <Link className="Link" to="/gettinghere"><h1 onClick={() => setShowMenu(false)}>{eng ? "Access" : "アクセス"}</h1></Link>
            <Link className="Link" to="/video"><h1 onClick={() => setShowMenu(false)}>{eng ? "Media" : "ギャラリー"}</h1></Link>
            <Link className="Link" to="/policy"><h1 onClick={() => setShowMenu(false)}>{eng ? "Policy" : "ポリシー"}</h1></Link>
            <br />
            <br />
            <div>
              <h1 className={eng ? "" : "underline"} onClick={() => setEng(false)}>JP</h1>
              &emsp;
              <h1 className={eng ? "underline" : ""} onClick={() => setEng(true)}>EN</h1>            
            </div>
            <br />
            <br />
            <br />

          </main>
        </div>

      </div>
    );
  };

  // const history = useHistory();
  // console.log(history)

  const [eng, setEng] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  // const [showPopup, setPopup] = useState(true)


  return (
    <div className="noscroll">
      {/* {showPopup &&
      <div className="popup">
         <h2 className="languageleft">
            <button className="languagebutton" onClick={() => setEng(false)}>{eng ? "JP" : <u>JP</u>}</button>
            &emsp;
            <button className="languagebutton" onClick={() => setEng(true)}>{eng ? <u>EN</u> : "EN"}</button>
          </h2>
      <h1 onClick={() => setPopup(false)} className="closeright">×</h1>
      <br /><br /><br /><br />
      {eng && 
      <div>
      <h1>Postponement Announcement</h1>
      <p>
      Friends of ishinoko,<br /><br />

In light of the evolving situation with Covid-19, with cases rising
and states of emergency across various regions of Japan being
announced and extended until the end of May, we have decided to
postpone ishinoko from the initial May 21-23 dates.<br /><br />

ishinoko will now take place on August 7-9, over the three-day
national holiday weekend, with a largely unchanged programme of music,
food, and activities. By this time the Covid-19 situation is expected
to stabilise and we can therefore ensure a safe event for all.
Everyone who has already bought a ticket will automatically be able to
use it for the the new dates. Of course refunds will be available for
all those who bought tickets for May but will not be able to join us
in August.<br /><br />

Our deep apologies for any disappointment and inconvenience that this
may cause. It was the last thing we wished for, but with many artists,
vendors, crew, and ticketholders travelling to ishinoko from outside
Ishikawa prefecture, we could not reasonably ask them to break
national guidelines to bring the event together. Safety comes first.<br /><br />

We are extremely grateful for the support of all of the team, artists,
and collaborators who have quickly helped us to re-organise ishinoko
amidst these difficult times. We feel very blessed to be working with
such a determined, kind, and talented group of people.<br /><br />

Our website has been updated to reflect the minor changes to lineups
and event information. Please do not hesitate to contact us via
 <a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a> or <a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">Instagram</a> with any questions.<br /><br />

We are working as hard as we can to make this a smooth transition, and
look forward to dancing with you all in August.<br /><br />

Until then...
<div className="safari_only" />
<div className="safari_only" />
</p></div>}
{!eng &&
<div>
<h1>延期について</h1>
<p>
ishinokoの仲間たちへ<br /><br />

日本各地で感染者が増加し、緊急事態が発表されました。5月末まで延長されているCovid-19の状況を鑑み、当初予定していた5月21日～23日のishinokoの開催をやむを得ず延期することを決定しました。<br /><br />

その代わり、ishinokoは8月7日〜9日の3連休に開催します。音楽、食事、アクティビティなどのプログラムはほぼ変更されません。すでにチケットを購入されている方は、新しい日程でもご利用いただけます。もちろん、5月のチケットを購入された方で、8月のイベントに参加できない方には、払い戻しいたします。<br /><br />

ご迷惑をおかけし大変申し訳ございません。私たちが望んだことではありませんが、多くのアーティスト、ベンダー、スタッフ、そしてチケット購入者が石川県外からこのイベントに参加するために、国の指針を破ってまでお願いすることはできませんでした。安全は第一です。<br /><br />

このような困難な状況の中、迅速にishinokoの再編成に協力してくれたチーム、アーティスト、協力者の皆さんには、大変感謝しています。私たちは、このような決意と優しさ、そして才能にあふれた人々と一緒に仕事ができることをとても幸せに感じています。<br /><br />

ラインナップやイベント情報のマイナーチェンジに伴い、ウェブサイトを更新いたします。ご不明な点がございましたら、<a href="mailto: ishinoko.jp@gmail.com">ishinoko.jp@gmail.com</a>、<a href="https://www.instagram.com/ishinoko.jp/" target="_blank" rel="noopener noreferrer">インスタ</a>までご連絡ください。<br /><br />

8月に皆様と一緒に踊ることをお楽しみにしておりますので、どうぞよろしくお願いいたします。<br /><br />

それまでは...
<div className="safari_only" />
<div className="safari_only" />
</p>
</div>}
      </div>} */}
       {/* {showPopup &&
      <div className="popup">
         <h2 className="languageleft">
            <button className="languagebutton" onClick={() => setEng(false)}>{eng ? "JP" : <u>JP</u>}</button>
            &emsp;
            <button className="languagebutton" onClick={() => setEng(true)}>{eng ? <u>EN</u> : "EN"}</button>
          </h2>
      <h1 onClick={() => setPopup(false)} className="closeright">×</h1>
      <br /><br /><br /><br />
      {eng && 
      <div>
      <h1>Postponement Announcement</h1>
      <p>
      Friends of ishinoko, 
We write with sad news. On Thursday, one week before the festival, we were informed by the Komatsu City Office that a new state of emergency will begin for Ishikawa Prefecture, starting tomorrow 7/31 through 8/21. Because of this it will be impossible to hold ishinoko on the planned dates from 8/7-9.<br /><br />
We have seen the covid cases rising over the past days and weeks, but this imperative from the local government has come with no prior warning from their side. We are shocked and disappointed that they have informed us at such short notice, but most of all we are saddened for all of you who were planning to come and join the festival next weekend.
Our next step is to look at whether it will be possible to reschedule the event for the autumn. We have been through this process once back in spring, and have a better idea this time of how to guard ourselves against any further postponements. We will be working closely with the village council in Takigahara, where ishinoko is to be held, and with the Komatsu City Office, to ensure that if the event is postponed to the autumn, this will be the final word and ishinoko will go ahead as planned.<br /><br />
We ask that you give us one week to make an alternative plan, while we consult with artists, vendors, and the relevant local authorities. We will announce the new plan by next Saturday 8/7 at the latest. After that announcement, anyone will be able to request a refund for their August ishinoko tickets, regardless of whether we cancel or simply postpone the event. We ask for your patience and understanding in the meantime.
If you have any questions don't hesitate to get in touch. We advise that you cancel any transportation and accommodation you had booked for next weekend, and we are deeply sorry for any costs that this will incur. Accommodation booked through us can be cancelled without a charge, please send us an email at ishinoko.jp@gmail.com to let us know.<br /><br />
This is not the news we were hoping for, but please know that we are working as hard as we physically can to find the best way through this storm.<br /><br />
We will write again soon, 
ishinoko team
<div className="safari_only" />
<div className="safari_only" />
</p></div>}
{!eng &&
<div>
<h1>再延期について</h1>
<p>
皆様に、大変悲しい連絡をしなければなりません。<br /><br />
木曜日の新型コロナウィルス感染拡大の影響により、開催県である石川県に7/31(土)から8/22(日)までまん延防止等重点措置が適用されることになりました。
それにより、昨日小松市役所の方からishinokoの開催を見送るように指示を受けることとなり、また滝ケ原町内会からも一部町民の皆様から開催に対して懸念の声があがっているということを伺っております。
ishinokoは地域を代表するお祭りを目指しており、上記のような自治体や町民の方々の声を無視して開催することは適当でないと判断し、8/7(土)から8/9(月)の期間での開催を断念し延期することを決定せざるを得なくなりました。
ishinokoオーガナイズチームはすでに開催準備で全てのメンバーが現地入りしており、ステージ、デコレーション、その他会場構成の制作をスタートしていました。
そのタイミングでこのような判断をすることは、我々にとっても大変悲しいことであり、来週末のishinokoに参加を予定していた皆様に対しても申し訳ない気持ちでいっぱいです。<br /><br />
私たちの次のステップは秋にイベントを開催することが可能かどうかを検討することです。
秋に延期された場合にはishinokoが予定通り開催されるように、開催場所である滝ヶ原の町内会や小松市と緊密に連携していきます。
アーティストや出店者、関係自治体と協議しながら代替案を作成しますので、1週間の猶予をいただきたいと思います。遅くとも来週8/7(土)までには、新しいプランを発表いたします。その発表後は、イベントの中止・延期にかかわらず、どなたでも8月のチケットの払い戻しを受けることができます。それまでの間、ご理解とご協力をお願いいたします。
ご不明な点がございましたらお気軽にお問い合わせください。<br /><br />
来週末に予約していた交通機関や宿泊施設をキャンセルしていただくことになりますが、その際に発生する費用については各自ご負担いただくことをご了承ください。
なお、イベントページを通して予約された宿泊施設は無料でキャンセルすることができますのでこのメールに返信してお知らせください。
私たちが期待していたニュースではありませんが、私たちはこの嵐の中で最善の方法を見つけるために、可能な限り努力していることをご理解ください。
また近いうちにご報告させていただきます。<br /><br />
よろしくお願いいたします。
ishinokoチーム
<div className="safari_only" />
<div className="safari_only" />
</p>
</div>}
      </div>} */}
      {/* {!showPopup && */}
      <Router>
        <div className="sidemenu">
          <Link to="/">
            <img width="80%" alt="logo" src={pixellogo} />
          </Link>
          <br />
          <Route exact path="/">
            {'>'}
          </Route> <Link className="Link" to="/">{eng ? "Home" : "ホーム"}</Link>
          <br />
          <Route exact path="/tickets">
            {'>'}
          </Route> <Link className="Link" to="/tickets">{eng ? "Tickets" : "チケット"}</Link>
          <br />
          <Route exact path="/music">
            {'>'}
          </Route> <Link className="Link" to="/music">{eng ? "Music" : "音楽"}</Link>
          <br />
          <Route exact path="/foodanddrink">
            {'>'}
          </Route> <Link className="Link" to="/foodanddrink">{eng ? "Food and Drink" : "飲食"}</Link>
          {/* <br />
          <Route exact path="/experiences">
            {'>'}
          </Route> <Link className="Link" to="/experiences">{eng ? "Experiences" : "エクスペリアンス"}</Link> */}
          <br />
          <Route exact path="/accomodation">
            {'>'}
          </Route> <Link className="Link" to="/accomodation">{eng ? "Accomodation" : "宿泊"}</Link>
          <br />
          <Route exact path="/gettinghere">
            {'>'}
          </Route> <Link className="Link" to="/gettinghere">{eng ? "Access" : "アクセス"}</Link>
          <br />
          <Route exact path="/video">
            {'>'}
          </Route> <Link className="Link" to="/video">{eng ? "Media" : "ギャラリー"}</Link>
          <br />
          <Route exact path="/policy">
            {'>'}
          </Route> <Link className="Link" to="/policy">{eng ? "Policy" : "ポリシー"}</Link>
          <br />
          <br />
          <div className="languages">
            <button className="languagebutton" onClick={() => setEng(false)}>{eng ? "JP" : <u>JP</u>}</button>
            &emsp;
            <button className="languagebutton" onClick={() => setEng(true)}>{eng ? <u>EN</u> : "EN"}</button>
          </div>
        </div>
        <div className="topbar">
          <Link to="/">
            <img onClick={() => setShowMenu(false)} width="50%" alt="logo" src={pixellogo} />
          </Link>
          {showMenu ?
            <div onClick={() => setShowMenu(false)} className="topbarright">×</div> :
            <div onClick={() => setShowMenu(true)} className="topbarright">MENU</div>}
          {/* </Link> */}
        </div>
        <Switch>
          <Route exact path="/">
            {showMenu ?
              <Menu /> :
              <App eng={eng} />
            }
          </Route>
          <Route exact path="/tickets">
            {showMenu ?
              <Menu /> :
              <Tickets eng={eng} />
            }
          </Route>
          <Route exact path="/accomodation">
            {showMenu ?
              <Menu /> :
              <Accomodation eng={eng} />
            }
          </Route>
          <Route exact path="/activities">
            <Activities />
          </Route>
          <Route exact path="/foodanddrink">
            {showMenu ?
              <Menu /> :
              <Foodanddrink eng={eng} />
            }
          </Route>
          <Route exact path="/experiences">
            {showMenu ?
              <Menu /> :
              <Experiences eng={eng} />
            }
          </Route>
          <Route exact path="/gettinghere">
            {showMenu ?
              <Menu /> :
              <Gettinghere eng={eng} />
            }
          </Route>
          <Route exact path="/music">
            {showMenu ?
              <Menu /> :
              <Music eng={eng} />
            }
          </Route>
          <Route exact path="/policy">
            {showMenu ?
              <Menu /> :
              <Policy eng={eng} />
            }
          </Route>
          <Route exact path="/success">
            <Success eng={eng} />
          </Route>
          <Route exact path="/video">
            {showMenu ?
              <Menu /> :
              <Media eng={eng} />
            }
          </Route>
          <Route exact path="/twentytwenty">
            <Twentytwenty />
          </Route>
          {/* <Route component={NotFound}/> */}
          <Route>
          <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
      {/* } */}
    </div>
  );
}

export default Everything;
